<template>
  <div id="controlls-container">
    <ul>
      <li>SPACE: toggle <i>light</i>screen</li>
      <li>SPACE + CTRL: toggle <b>full</b>screen</li>
    </ul>
    more soon to come...
  </div>
</template>

<script>
export default {
  name: 'Controlls',
}
</script>
